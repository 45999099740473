import React from 'react';
import {H} from 'highlight.run';
import {ErrorBoundary} from '@highlight-run/react';
import renderHydrogen from '@shopify/hydrogen/entry-client';
import {CustomErrorView} from './components/error/CustomErrorView.client';

type Props = {
  children: React.ReactNode;
};

const ClientWrapper = (props: Props) => {
  return process.env.NODE_ENV === 'development' ? (
    <>{props.children}</>
  ) : (
    <ErrorBoundary showDialog customDialog={<CustomErrorView />}>
      {props.children}
    </ErrorBoundary>
  );
};

H.init('0dqq94do', {
  environment: process.env.NODE_ENV || 'production',
});

export default renderHydrogen(ClientWrapper, {});
