import {Image} from '@shopify/hydrogen';

export default function LogoIcon({flip}: any) {
  return (
    <div className="relative z-20 w-[130px] lg:w-[223px] lg:self-center">
      <Image
        width="223px"
        height="31px"
        loading="eager"
        data={{
          url: flip
            ? 'https://cdn.sanity.io/images/15oppjc2/production/89a9bb7af7976e5c1cc6c6b02c9586bf66e57471-446x62.png'
            : 'https://cdn.sanity.io/images/15oppjc2/production/aa1b3cf1c79560d984a0ef0de88b4307027c0407-446x62.png',
        }}
        className="w-full"
        alt="Goodnature Logo"
      />
    </div>
  );
}
