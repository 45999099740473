import {useState, useRef} from 'react';
import LogoIcon from '../icons/Logo';
import {motion, AnimatePresence} from 'framer-motion';
import Loader from '../global/Loader';
import {useUrl} from '@shopify/hydrogen';

export const CustomErrorView = () => {
  console.error('🔴 Error view rendered');
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const {pathname} = useUrl();
  const isSubdirectory = pathname.split('/')[1].includes('-en');
  const countryCode = isSubdirectory
    ? pathname.split('/')[1].split('-')[0].toUpperCase()
    : 'NZ';

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const res = await fetch('/api/send-error-message', {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: nameRef.current?.value,
          email: emailRef.current?.value,
          message: messageRef.current?.value,
          countryCode,
          pathname,
        }),
      });
      setSubmitted(res.status === 200);
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="absolute left-0 top-0 h-full w-full grow  overflow-x-hidden  ">
      <a
        href="/"
        className="absolute left-1/2 top-0 z-30 -translate-x-1/2 py-[30px]"
      >
        <LogoIcon flip={true} />
      </a>
      <div className="mx-auto flex h-full flex-col  justify-between pt-[100px] lg:pt-[157px] ">
        <div className="mx-auto  w-full px-5">
          <AnimatePresence mode="wait">
            {submitted ? (
              <motion.div
                key={`${submitted}`}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                className="mx-auto max-w-[882px] space-y-10 text-center"
              >
                <span className="blockH2 font-bold text-orange">Thank you</span>
                <p className="richH3 text-dark-grey">
                  Our team will be notified and we will look into the issue as
                  soon as we can.
                </p>

                <a href="/" className="btn-lg">
                  Go to the Goodnature homepage
                </a>
              </motion.div>
            ) : (
              <motion.div
                key={`${submitted}`}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                className="w-full"
              >
                <form onSubmit={handleSubmit} className="w-full">
                  <div className="mb-[60px] space-y-10 text-center">
                    <h1 className="blockH2 font-bold text-orange">Whoops</h1>
                    <p className="richH3 text-dark-grey">
                      Looks like something went wrong.
                      <br />
                      Refresh the page to try again.
                    </p>
                    <button onClick={handleRefresh} className="btn-lg">
                      Refresh Page
                    </button>
                    <a
                      href="/"
                      className="block text-[14px] text-dark-grey underline hover:text-orange"
                    >
                      Go to the Goodnature homepage
                    </a>
                  </div>
                  <div className=" mx-auto w-full max-w-[938px] border-t border-dark-grey pt-[60px]">
                    <p className="richH3 mb-10 text-center text-dark-grey">
                      Alternatively, you can let us know what happened.
                    </p>

                    <div className="mx-auto flex max-w-[717px] flex-col gap-y-4   md:gap-y-5">
                      <input
                        name="name"
                        id="name"
                        className="input-standard !bg-light-grey"
                        type="text"
                        placeholder="Name"
                        required
                        ref={nameRef}
                      />
                      <input
                        name="email"
                        id="email"
                        className="input-standard !bg-light-grey"
                        type="email"
                        placeholder="Email Address"
                        required
                        ref={emailRef}
                      />
                      <textarea
                        name="message"
                        id="message"
                        className="input-standard !bg-light-grey"
                        placeholder="What happened?"
                        rows={6}
                        ref={messageRef}
                      />
                      <button
                        type="submit"
                        className="btn-standard mx-auto"
                        disabled={submitting}
                      >
                        {submitting ? (
                          <Loader size="Medium" className="ml-2" />
                        ) : (
                          'Submit'
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="h-auto w-full">
          <img
            src="/images/rock-bird.jpg"
            alt="Bird on a rock"
            className="mx-auto h-full w-full"
          />
        </div>
      </div>
    </div>
  );
};
